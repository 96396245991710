<template>
  <v-card dense flat max-width="90%" class="mx-auto">
    <showFilteredPosts :personalMode="true" :postsStatus="'done'" />
  </v-card>
  <!-- <OneTable :mainHeader="'בוצע'" :postState="'done'" :trucking="trucking" /> -->
</template>

<script>
import showFilteredPosts from '@/components/showRecord/showFilteredPosts.vue'

// import OneTable from "@/components/app/common/Table/OneTable2";
export default {
  props: {
    trucking: {
      default: false,
    },
  },
  components: {
    // OneTable,
    showFilteredPosts
  },
};
</script>

<style></style>