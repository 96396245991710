var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{key:_vm.updateTable,attrs:{"headers":_vm.headersFiltered,"items":_vm.filteredRecords,"single-expand":false,"expanded":_vm.expanded,"item-key":"name","multi-sort":"","itemKey":'id',"loading":_vm.loadingTable},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.clickRow},scopedSlots:_vm._u([(!_vm.previewMode)?{key:"top",fn:function(){return [_c('FilterCard',{attrs:{"HeadersFiltered":_vm.headersFiltered,"records":_vm.records},on:{"containsUpdated":_vm.containsUpdated,"addDialog":function($event){_vm.addDialog = true}}}),_c('v-text-field',{staticClass:"mx-9 mt-14",attrs:{"clearable":"","label":'חיפוס לפי מספר מודעה',"outlined":"","prepend-inner-icon":"mdi-clipboard-search-outline","color":"deep-orange lighten-2"},model:{value:(_vm.searchPostID),callback:function ($$v) {_vm.searchPostID=$$v},expression:"searchPostID"}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.mainHeader))]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-3",attrs:{"color":"red darken-1","disabled":_vm.expanded.length === 0,"text":""},on:{"click":function($event){_vm.expanded = []}}},[_vm._v(" סגור הכל ")]),_c('v-dialog',{attrs:{"scrollable":"","max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","color":"deep-orange lighten-2"}},'v-btn',attrs,false),on),[_vm._v(" בחר טורים "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-format-columns")])],1)]}}],null,false,1391888598),model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('SelectedColumns',{attrs:{"title":'טורים',"items":_vm.allHeaders},on:{"Agree":_vm.addAgree,"Cancel":_vm.addCancel}})],1)],1)]},proxy:true}:null,{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('ExpandedCard',{attrs:{"item":item,"headers":_vm.allHeaders,"allUsers":_vm.allUsers,"trucking":_vm.trucking},on:{"relocateTo":_vm.relocateTo,"containsUpdated":_vm.containsUpdated}})],1)]}},{key:"item.fromCity",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mx-1",attrs:{"dark":false,"color":"primary","left":""}},[_vm._v("mdi-alpha-a-circle")]),_vm._v(_vm._s(item.fromCity)+" ")]}},{key:"item.toCity",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mx-1",attrs:{"dark":false,"color":"primary","left":""}},[_vm._v("mdi-alpha-b-circle")]),_vm._v(_vm._s(item.toCity)+" ")]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mx-1",attrs:{"dark":false,"color":"deep-orange lighten-2","left":""}},[_vm._v("mdi-calendar")]),_vm._v(_vm._s(_vm._f("date")(item.startDate,"date"))+" ")]}},{key:"item.crane",fn:function(ref){
var item = ref.item;
return [(item.crane === true || item.crane === 'true')?_c('v-icon',{attrs:{"dark":false}},[_vm._v("mdi-tow-truck")]):_vm._e()]}},_vm._l((_vm.checkBoxeItems),function(checkBoxeItem){return {key:("item." + (checkBoxeItem.name)),fn:function(ref){
var item = ref.item;
return [(
          item[checkBoxeItem.name] === true ||
          item[checkBoxeItem.name] === 'true'
        )?_c('v-icon',{key:checkBoxeItem.name,attrs:{"color":"green","dark":false}},[_vm._v("mdi-check-bold")]):_vm._e(),(
          item[checkBoxeItem.name] === false ||
          item[checkBoxeItem.name] === 'false'
        )?_c('v-icon',{key:checkBoxeItem.name,attrs:{"color":"red","dark":false}},[_vm._v("mdi-close-thick")]):_vm._e()]}}}),{key:"item.portersNeed",fn:function(ref){
        var item = ref.item;
return [(item.portersNeed === true || item.crane === 'true')?_c('v-icon',{attrs:{"dark":false}},[_vm._v("mdi-account-hard-hat")]):_vm._e()]}},{key:"item.price",fn:function(ref){
        var item = ref.item;
return [(item.price !== 0 && item.price != '')?_c('div',[_vm._v(" "+_vm._s(item.price)+" "),_c('span',{staticClass:"text--disabled text-caption",attrs:{"dark":false,"color":"primary"}},[_vm._v("ש\"ח")])]):_c('div',[_c('v-icon',{staticClass:"mx-1",attrs:{"dark":false,"color":"deep-orange lighten-2","left":""}},[_vm._v("mdi-minus")])],1)]}},{key:"item.coments",fn:function(ref){
        var item = ref.item;
return [(item.coments.length)?_c('div',[_vm._v(" "+_vm._s(item.coments.substring(0, 12))+" "),(item.coments.length > 12)?_c('span',[_vm._v("...")]):_vm._e()]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }