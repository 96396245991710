<template>
  <div>
    <v-progress-linear color="indigo" height="10" indeterminate v-if="loading">
    </v-progress-linear>
    <v-container fluid class="pa-3 mb-6" v-else>
      <v-row v-if="!userDoesNotExist">
        <v-col md="3" cols="12">
          <v-row dense>
            <v-col>
              <span class="text--secondary float-right mx-3">מס הודעה: </span>
              <span class="text--primary"> {{ item.id }} </span>
              <v-icon @click="copyToClipboard(item.id)" dense class="mx-2"
                >mdi-content-copy</v-icon
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col md="3" cols="12">
          <v-row dense>
            <v-col>
              <span class="text--secondary float-right mx-3"
                >תעריך פרסום:
              </span>
              <span class="text--primary">
                {{ item.postDate | date("date") }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="3" cols="12">
          <v-row dense>
            <v-col>
              <span
                class="text--secondary float-right mx-3"
                v-if="item.lastUpdatedDate"
                >תאריך שינוי אחרון:
              </span>
              <span class="text--primary" v-if="item.lastUpdatedDate">
                {{ item.lastUpdatedDate | date("date") }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="3" cols="12"> </v-col>
        <v-col
          md="2"
          cols="12"
          v-for="header in fullHeaders"
          :key="header.text"
        >
          <v-row dense>
            <v-col>
              <span class="text--secondary float-right mx-3"
                >{{ header.text }}
              </span>
              <span class="text--primary">
                {{ translate(item[header.value]) }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="2" cols="12" v-if="allUsers || trucking">
          <v-row dense>
            <v-col>
              <span class="text--secondary float-right mx-3"
                >מפרסם ההודעה
              </span>
              <span class="text--primary"> {{ userName }} </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="2" cols="12" v-if="allUsers && contactPersonLoaded != ''">
          <v-row dense>
            <v-col>
              <span class="text--secondary float-right mx-3">איש קשר </span>
              <span class="text--primary"> {{ contactPersonLoaded }} </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="2" cols="12" v-if="allUsers && workGiverLoaded !=''">
          <v-row dense>
            <v-col>
              <span class="text--secondary float-right mx-3">חברה </span>
              <span class="text--primary"> {{ workGiverLoaded }} </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col md="12" cols="12">
          <v-row dense>
            <v-col>
              <span class="text--secondary float-right mx-3"
                >משתמש זה כבר לא קיים במערכת, הודעה לא רלוונטית</span
              >
              <span class="text--primary"></span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div v-if="!userDoesNotExist">
        <div v-if="!allUsers" class="mt-3">
          <personalActionButtons
            v-if="!trucking"
            :headers="headers"
            :item="item"
          />
        </div>
        <div v-else class="mt-3">
          <RFormNewPorposal
            :buttonName="proposalText"
            :disabled="!enablePorposal"
            :startPrice="item['price']"
            :to="this.item.uid"
            :postID="this.item.id"
            @makeProposal="makeProposal"
          />
          <RFormNewMessage
            :buttonName="'שלח הודעה'"
            :disabled="this.item.uid === UID"
            :to="this.item.uid"
          />
          <RModalPersonalData
            v-if="UID"
            :title="'פרטי המפרסם'"
            :editMode="false"
            :user="user"
          />
        </div>
        <RFormNewMessage
          v-if="trucking"
          :buttonName="'שלח הודעה'"
          :disabled="this.item.uid === UID"
          :to="this.item.uid"
        />
        <RModalShowProposals
          v-if="trucking"
          :post="item"
          :trucking="trucking"
          :title="'ההצעה שלי'"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
// import fire from "@/main";
import { mapGetters } from "vuex";

import personalActionButtons from "@/components/app/common/Table/elements/ExpendedCardComponents/personalActionButtons";
import RFormNewPorposal from "@/components/app/common/Form/RFormNewPorposal";
import RFormNewMessage from "@/components/app/common/Form/RFormNewMessage";
import RModalShowProposals from "@/components/app/common/modals/RModalShowProposals";
import RModalPersonalData from "@/components/app/common/modals/RModalPersonalData";

export default {
  props: {
    headers: {},
    item: {},
    allUsers: {
      default: false,
    },
    trucking: {
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      editable: false,
      user: null,
      contactPerson: null,
      workGiver: null,
      addDialog2: false,
      addDialog3: false,
      userDoesNotExist: false,
    };
  },
  components: {
    personalActionButtons,
    RFormNewPorposal,
    RFormNewMessage,
    RModalShowProposals,
    RModalPersonalData,
  },
  methods: {
    async makeProposal(payload) {
      await this.$store.dispatch("addPorposal", {
        publisherUID: this.item.uid,
        proposalerUID: this.UID,
        postUID: this.item.id,
        price: payload.price,
        comments: payload.comments,
        timeStamp: Date(),
      });
      console.log({
        publisherUID: this.item.uid,
        proposalerUID: this.UID,
        postUID: this.item.id,
      });
      // await fire
      //   .database()
      //   .ref(`recors/lagguage/${this.item.postState}/`)
      //   .child(this.item.currentID)
      //   .update({proposalerUID: this.UID});
      await this.$store.dispatch("getProposalByPostID", {
        postUID: this.item.id,
        forceUpdate: true,
      });
    },
    async userNameByUID(userID) {
      let user = "";
      if (userID) {
        user = await this.$store.dispatch("getUserByID", { uid: userID });
      }
      return user;
    },
    copyToClipboard(payload) {
      navigator.clipboard.writeText(payload);
    },
    async getContactPerson() {
      try {
        this.user = await this.$store.dispatch("getUserByID", {
          uid: this.item.uid,
        });
        if (this.item.contactPerson) {
          this.contactPerson = await this.$store.dispatch(
            "getContactPersonByUserByID",
            {
              uid: this.user.uid,
              id: this.item.contactPerson,
            }
          );
        }
        // console.log("user", this.user);
        if (this.user.workerOf && this.user.workerOf != "") {
          this.workGiver = await this.$store.dispatch("getUserFullByID", {
            uid: this.user.workerOf,
          });
          // console.log("workGiver", this.workGiver);
        }
      } catch (error) {
        this.userDoesNotExist = true;
      }

      this.loading = false;
    },
    translate(payload) {
      // console.log(payload);
      if (!payload) return "";
      if (payload === true) {
        return "כן";
      }
      if (payload === false) {
        return "לא";
      }
      return payload;
    },
  },
  computed: {
    ...mapGetters(["UID", "proposals"]),
    contactPersonLoaded() {
      if (this.contactPerson != null) {
        if (this.contactPerson.name != "") {
          return this.contactPerson.name;
        }
      }
      return "";
    },
    workGiverLoaded() {
      if (this.workGiver != null) {
        if (this.workGiver.companyInfo.companyName != "") {
          return this.workGiver.companyInfo.companyName;
        }
      }
      return "";
    },
    userName() {
      if (this.user) {
        if (this.user.name) {
          return this.user.name;
        }
      }
      return "";
    },
    enablePorposal() {
      // let proposerUID = this.$store.dispatch("getUID");
      if (this.UID === this.item.uid) {
        return false;
      }
      let res = null;
      if (this.proposals.length > 0) {
        res = this.proposals.find((v) => {
          if (v.postUID === this.item.id && v.proposalerUID === this.UID)
            return v;
        });
      }
      console.log({ "this.item.id": this.item.id });
      console.log({ res: res, proposals: this.proposals });
      if (res) {
        return false;
      }
      return true;
    },
    proposalText() {
      if (this.UID === this.item.uid) {
        return "מודעה שלך";
      }
      let res = null;
      if (this.proposals.length > 0) {
        res = this.proposals.find((v) => {
          if (v.postUID === this.item.id && v.proposalerUID === this.UID)
            return v;
        });
      }
      console.log({ "this.item.id": this.item.id });
      console.log({ res: res, proposals: this.proposals });
      if (res) {
        return "הצעה נשלך";
      }
      return "שלח הצעה";
    },
    fullHeaders() {
      let res = this.headers.filter((v) => {
        if (this.item[v.value] && this.item[v.value] != "") {
          return v;
        }
      });
      return res;
    },
  },
  async created() {
    if (this.allUsers || this.trucking) {
      this.getContactPerson();
    } else {
      this.loading = false;
    }
  },
};
</script>

<style>
</style>