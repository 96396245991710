<template>
  <v-dialog persistent v-model="showDialog" max-width="950" v-if="!loading">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mx-2"
        color="primary"
        elevation="0"
        v-bind="attrs"
        v-on="on"
      >
        {{ title }}
        <v-icon right>mdi-account</v-icon>
      </v-btn>
    </template>
    <v-card class="elevation-9 rounded-xl">
      <v-card-title style="position: sticky" class="primary mb-5">
        <span class="white--text mx-1"> {{ title }}</span>
        <v-spacer></v-spacer>
        <v-icon color="white" @click="showDialog = false">mdi-close </v-icon>
      </v-card-title>
      <v-card-text flat tile>
        <PersonalData :editMode="editMode" :shorten="true" :user="user"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
// import fire from "@/main";

// import RFormNewMessage from "@/components/app/common/Form/RFormNewMessage";
import PersonalData from "@/components/app/MySpace/PersonalData/components/PersonalData";

export default {
  props: {
    post: {},
    trucking: {
      default: false,
    },
    title: {
      default: "הצעות",
    },
    editMode: {
      default: true,
    },
    user:{}
  },
  data() {
    return {
      loading: true,
      showDialog: false,
      showEditDialog: false,
      proposals: [],
    };
  },
  computed: {
    ...mapGetters(["UID"]),
  },
  async created() {
    // await this.loadPorposals();
    console.log("user",this.user);
    this.loading = false;
  },
  methods: {
    // async loadPorposals() {
    //   let postUID = this.post.id;
    //   let viewMessage = this;
    //   let itemsRef = await fire
    //     .database()
    //     .ref(`recors/lagguage/proposals/`)
    //     .orderByChild("postUID")
    //     .equalTo(postUID);
    //   await itemsRef.on("value", async (snapshot) => {
    //     viewMessage.loading = false;
    //     let data = snapshot.val();
    //     if (data) {
    //       this.loading = true;
    //       viewMessage.proposals.length = 0;
    //       let arr_tmp = [];
    //       let keys_arr = Object.keys(data);
    //       for (let key of keys_arr) {
    //         let arr_tmp_ready = {
    //           id: key,
    //           ...data[key],
    //         };
    //         console.log({ arr_tmp_ready: arr_tmp_ready });
    //         arr_tmp_ready.proposalerName = null;
    //         arr_tmp_ready.proposalerName = await this.$store.dispatch(
    //           "getUserByID",
    //           { uid: arr_tmp_ready.proposalerUID }
    //         );
    //         if (this.trucking) {
    //           if (arr_tmp_ready.proposalerUID === this.UID) {
    //             arr_tmp.push(arr_tmp_ready);
    //           }
    //         } else {
    //           arr_tmp.push(arr_tmp_ready);
    //         }
    //       }
    //       viewMessage.proposals = arr_tmp;
    //       viewMessage.loading = false;
    //     }
    //   });
    // },
  },
  components: {
    // RFormNewMessage,
    PersonalData,
  },
};
</script>

<style>
</style>