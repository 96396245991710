<template>
  <v-container fluid v-if="userInfo && !loading">
    <SubHeader
      :title="'פרטים אישיים'"
      :toClone="false"
      :toDelete="false"
      :editMode="editMode"
      @edit="edit"
      @saveChanges="saveChanges"
      @cencel="cencel"
    />
    <v-text-field
      :label="'שם'"
      v-model="userInfoTMP.name"
      :disabled="!editable"
      :type="'text'"
    ></v-text-field>
    <v-select
      v-if="!shorten"
      :label="'תפקיד'"
      :loading="loading"
      :disabled="!editable"
      :items="userRoles || []"
      v-model="userInfoTMP.userRole"
    ></v-select>
    <v-text-field
      v-if="!shorten"
      :label="'ת.ז.'"
      v-model="userInfoTMP.bussinesNumber"
      :disabled="!editable"
      :type="'text'"
    ></v-text-field>
    <v-text-field
      :label="'מייל עסקי'"
      v-model="userInfoTMP.secondEmail"
      :disabled="!editable"
      :type="'mail'"
    ></v-text-field>
    <v-text-field
      :label="'טלפון'"
      v-model="userInfoTMP.phoneNumber"
      :disabled="!editable"
      :type="'text'"
    ></v-text-field>

    <div v-if="userInfoTMP.bussiness">
      <v-checkbox
        @change="changeContactPersons"
        dense
        v-model="userInfoTMP.showContactPersons"
        :label="`הצג אנשי קשר`"
      ></v-checkbox>
      <v-checkbox
        @change="changeShowCars"
        dense
        v-model="userInfoTMP.showCars"
        :label="`הצג רכבים`"
      ></v-checkbox>
    </div>

    <div>
      מספר אישי:
      <span class="text--disabled caption">{{ userInfoTMP.uid }}</span>
    </div>
    <div>
      תאריך הרשמה:
      <span class="text--disabled caption">{{
        userInfoTMP.createdAt | date("date")
      }}</span>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import SubHeader from "@/components/app/common/Form/SubHeader";
export default {
  name: "PersonalData",
  props: {
    editMode: {
      default: true,
    },
    user: {},
    shorten: {
      default: false,
    },
  },
  data: () => ({
    editable: false,
    userInfoTMP: {},
  }),
  methods: {
    async saveChanges() {
      await this.$store.dispatch("loading", true);
      await this.$store.dispatch("updateUserInfo", this.userInfoTMP);
      await this.reloadUserInfo();
      this.editable = false;
    },
    async edit() {
      this.editable = true;
    },
    async cencel() {
      await this.reloadUserInfo();
      this.editable = false;
    },
    async reloadUserInfo() {
      await this.$store.dispatch("loading", true);
      let ui_tmp = await this.$store.dispatch("refreshUserInfo");
      this.userInfoTMP = Object.assign(this.userInfoTMP, ui_tmp);
      await this.$store.dispatch("loading", false);
    },
    async changeContactPersons() {
      console.log(this.userInfoTMP.showContactPersons);
      await this.$store.dispatch("loading", true);
      await this.$store.dispatch("updateUserInfo", this.userInfoTMP);
      await this.reloadUserInfo();
    },
    async changeShowCars() {
      console.log(this.userInfoTMP.showCars);
      await this.$store.dispatch("loading", true);
      await this.$store.dispatch("updateUserInfo", this.userInfoTMP);
      await this.reloadUserInfo();
    },
  },
  components: {
    SubHeader,
  },
  async created() {
    await this.$store.dispatch("loading", true);
    await this.$store.dispatch("getUserRoles");
    await this.$store.dispatch("getUserInfo");
    console.log("user in PersonalData", this.user);
    if (this.user) {
      this.userInfoTMP = Object.assign(this.userInfoTMP, this.user);
      console.log("userInfoTMP", this.userInfoTMP);
      await this.$store.dispatch("loading", false);
    } else {
      await this.reloadUserInfo();
    }
    // console.log("this.userInfoTMP", this.userInfoTMP);
    // this.name = this.userInfo.name
  },
  computed: {
    ...mapGetters(["loading", "userInfo", "userRoles"]),
  },
};
</script>