<template>
  <v-row>
    <v-col>
      <div class="text-h6">{{ title }}</div>
    </v-col>
    <v-col>
      <div class="float-left" v-if="editMode">
        <v-tooltip v-show="!editable" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="!editable"
              icon
              v-bind="attrs"
              v-on="on"
              text
              color="gray"
              small
              @click="edit"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>לשנות</span>
        </v-tooltip>
        <v-tooltip v-show="editable" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              text
              color="green lighten-2"
              v-show="editable"
              small
              @click="saveChanges"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>לשמור</span>
        </v-tooltip>
        <v-tooltip v-show="editable && toDelete" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              text
              color="red lighten-2"
              v-show="editable && toDelete"
              small
              @click="delEl"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>למחוק</span>
        </v-tooltip>
        <v-tooltip v-show="!editable && toClone" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="!editable && toClone"
              v-bind="attrs"
              v-on="on"
              icon
              text
              color="deep-orange lighten-2"
              small
              @click="clone"
            >
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>לשכפל</span>
        </v-tooltip>
        <v-tooltip v-show="editable" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              text
              color="gray"
              v-show="editable"
              small
              @click="cencel"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>ביטול</span>
        </v-tooltip>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    title: { default: "" },
    toClone: { default: false },
    toDelete: { default: true },
    editMode: {
      default: true,
    },
  },
  data: () => ({
    editable: false,
  }),
  methods: {
    edit() {
      this.$emit("edit");
      this.editable = true;
    },
    saveChanges() {
      this.$emit("saveChanges");
      this.editable = false;
    },
    delEl() {
      this.$emit("delEl");
    },
    cencel() {
      this.editable = false;
      this.$emit("cencel");
    },
    clone() {
      this.$emit("clone");
    },
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
  },
};
</script>

<style>
</style>